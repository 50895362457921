@use '@/scss/underscore' as _;

.withTable {
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: _.unit(6) _.unit(8);

  > *:not(:first-child) {
    margin-left: _.unit(6);
  }

  .icon {
    margin-left: _.unit(2);
    width: 60px;
    height: 60px;
  }

  .info {
    flex: 1;

    .name {
      font: var(--font-title-1);
      color: var(--color-text);
    }

    .meta {
      display: flex;
      align-items: center;

      > * {
        display: inline-block;
      }

      > :not(:first-child) {
        margin-left: _.unit(2);
      }

      .idText {
        font: var(--font-label-2);
        color: var(--color-text-secondary);
      }

      .verticalBar {
        @include _.vertical-bar;
        height: 12px;
      }
    }
  }

  .moreIcon {
    color: var(--color-text-secondary);
  }
}
